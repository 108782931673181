@import url('https://fonts.googleapis.com/css2?family=PT+Serif&family=Cantarell:wght@700&family=Cute+Font&family=Parisienne&family=IBM+Plex+Sans:wght@300&family=Roboto+Slab:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Serif&family=Cantarell:wght@700&family=Cute+Font&family=Parisienne&family=IBM+Plex+Sans:wght@300&family=Roboto+Slab:wght@300&display=swap');

body {
  cursor: url('../images/watermelon.jpg'), auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0px;
  background-color: rgb(231, 216, 235);
  font-family: 'IBM Plex Sans', sans-serif;
  color: rgba(30, 4, 21, 0.85);
  font-size: 20px;
  text-align: center;
}


.Collapsible {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: #c4978c;

}

.active,
.Collapsible:hover {
  background-color: #b7866d;
}


.content {
  padding: 0 1.5rem;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

a {
  margin-top: 10px;
}

a:link {
  color: rgb(72, 7, 88);
  background-color: transparent;
  text-decoration: none;
}

a:visited {
  color: rgb(108, 63, 110);
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: rgb(149, 69, 215);
  background-color: transparent;
  text-decoration: underline;
}

a:active {
  color: rgb(233, 161, 222);
  background-color: transparent;
  text-decoration: underline;
}

.content {
  padding: 0 1.5rem;
  display: none;
  overflow: hidden;
  background-color: #f1f1f1;
}

.memory-container {
  text-align: center;
  margin-top: 0;
  align-items: center;
  max-width: 70%;
  font-family: 'Roboto Slab', serif;

}

.project-points {
  text-align: left;
  list-style: none;
  flex: 1;
}

li::before {
  content: "\2606";
  color: rgb(101, 60, 89);

  margin-right: 10px;
  /* Optional tweak */
}

.project-box {
  background-color: rgb(210, 190, 220);
  margin-top: 20px;
  margin-bottom: 10px;
  border-radius: 15px;
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 10px;
}

.project-inner-box {
  display: flex;
  align-items: center;
  justify-content: space-between;

}


.project-content {
  flex-grow: 1;
}


.memory-intro {
  font-family: 'PT Serif', serif;
  font-size: 15px;
}

.memory-title {
  font-family: 'Parisienne';
}

.h4 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.container {
  text-align: center;
  margin-top: 0px;
  overflow: hidden;
  max-width: 70%;
}

.title {
  font-size: 45px;
  font-family: 'PT Serif', serif;
}

.index_container {
  text-align: center;
  margin-top: 100px;
  overflow: hidden;
  max-width: 70%;
}

.profile-container {

  display: flex;
  align-items: center;
}

.profile-image {
  width: 15vw;
  height: auto;
  margin-left: 13%;
  border-radius: 15px;
  box-shadow: 5px 5px 5px #999;

}

.self-intro {
  margin-left: 5%;
  flex: 1;
  text-align: left;
  background-color: #fcecff;
  padding: 4.5%;
  border-radius: 15px;


}

.image-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.gallery-image {
  margin: 15px 15px 15px 15px;
  max-width: 100%;
  height: auto;
  width: 15vw;
  border-radius: 15px;
  box-shadow: 5px 5px 5px #999;
}

.hover_img {
  position: relative;

}

.hover_img a span {
  position: absolute;
  display: none;
  z-index: 1;
  top: 100%;
  left: 50%;

}

.hover_img a:hover span {
  display: block;
  height: auto;
  width: 500px;
  overflow: visible;
}

img {
  max-width: 100%;
  height: auto;
  width: 20vw;
  border-radius: 20px;
}

p {
  text-align: center;
}



/* Common styles */
.Button {
  font-family: 'IBM Plex Sans', sans-serif;
  border: 0px;
  font-size: 17px;
  width: 100px;
  min-height: 20px;
  color: rgb(255, 255, 255);
  padding: 15px 15px;
  border-radius: 100px;
  background-color: rgb(231, 216, 235);
  transition-timing-function: ease-in;
  transition-duration: 0.3s;
  cursor: pointer;
  font-weight: 600;
  margin-top: 20px;
  background-color: rgb(129, 50, 151);
  width: 150px;
  margin: 0;
  line-height: 30px;
  margin-right: 1%;
  margin-top: 3%;
}

.Button:hover {
  background-color: rgb(150, 77, 170);

}

.ContactButton {
  /* Additional styles specific to Contact button */
  margin-right: 10px;
  /* Adjust this margin to create space between buttons */
}

.ButtonContainer {
  text-align: center;
  padding-bottom: 60px;
  align-items: center;

}

.animated-text {
  animation: slideIn 2s ease-in-out;
  /* Adjust animation duration and easing */
}

.wave-text {
  position: relative;
  display: inline-block;
  animation: wave 2s infinite linear;
}

@keyframes wave {

  0%,
  100% {
    transform: translateY(0);
  }

  25% {
    transform: translateY(-10px);
  }

  50% {
    transform: translateY(0);
  }

  75% {
    transform: translateY(10px);
  }
}



.larger-icon {
  font-size: 30px;
  margin-right: 30px;
}





.link:hover {
  color: rgb(119, 36, 93);
}

.bold-text {
  font-weight: bold;
}


#drinkButton,
#hobbyButton {
  text-decoration: underline;
  color: rgb(0, 0, 0);
  cursor: pointer;
}


#drinkButton:hover,
#hobbyButton:hover {
  color: rgb(103, 50, 119);
}